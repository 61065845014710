import { useEffect, useState, useRef } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, Navigate, useParams, useLocation } from "react-router-dom";

import Cookies from 'js-cookie';
import { callFetch } from "../../../helpers/callFetch";
import { Link } from "react-router-dom";
// @mui material components
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import HomeIcon from '@mui/icons-material/Home';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import Tooltip from "@mui/material/Tooltip";
import SoftAvatar from "components/SoftAvatar";
import ArrowBack from '@mui/icons-material/ArrowBack';
import SoftBox from "components/SoftBox";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import team5 from "assets/images/team-5.jpg";
function NavMenu(props, light) {
    let params = useParams();
    console.log(params);
    const { t } = useTranslation();
    let navigate = useNavigate();
    const errorNotify = () => toast(t("Fill Out Lead"));
    const [activeTab, setActiveTab] = useState('callcenter');
    const [saveBtn, setSaveBtn] = useState(false);
    const [saveBtnTwo, setSaveBtnTwo] = useState(false);
    const [saveBtnThree, setSaveBtnThree] = useState(false);
    const [actionBtn, setActionBtn] = useState(true);
    const [dcabname, setDcabname] = useState(false)
    const [data, setData] = useState([]);
    const [invoices, setInvoices] = useState([]);
    const [deActiveDist, setDeActiveDist] = useState(false);
    const [quoatationSuccess, setQuoatationSuccess] = useState(true);
    const callCenter = useRef(null);
    const [menu, setMenu] = useState(null);
    const openMenu = (event) => setMenu(event.currentTarget);
    const closeMenu = () => setMenu(null);
    const [approve, setApprove] = useState(0);
    const [members, setMembers] = useState([]);
    const [fertigmeldungErfolgreich, setFertigmeldungErfolgreich] = useState(0);
    const [refresh, setRefresh] = useState(0);
    const route = useLocation().pathname.split("/").slice(1);
    const routes = route.slice(0, -1);

    const renderMembers = members.map(({ image, name }) => (
        <Tooltip key={name} title={name} placement="bottom">
            <SoftAvatar
                src={image?.length > 0 ? process.env.REACT_APP_STORAGE_URL + 'storage/avatar/' + image : '/assets/img/placeholder.png'}
                alt={name}
                size="sm"
                sx={({ borders: { borderWidth }, palette: { white } }) => ({
                    ml: -1.25,
                    //border: `${borderWidth[2]} solid ${white.main}`,
                    cursor: "pointer",
                    position: "relative",

                    "&:hover, &:focus": {
                        zIndex: "10",
                    },
                })}
            />
        </Tooltip>
    ));

    const activeDataTab = (data) => {
        if (data == 'callcenter') {
            setActiveTab(data);
            props.activeTab(data);

            setSaveBtn(false);
            setSaveBtnTwo(false);
            setActionBtn(true);
            setDcabname(false)
        } else {
            if (params?.id) {
                setActiveTab(data);
                props.activeTab(data);

                if (data == 'auftrag') {
                    setActionBtn(true);
                    setSaveBtnTwo(true);
                    setSaveBtn(false);
                    setSaveBtnThree(false);
                    setDcabname(false)
                } else if (data == 'distribution') {
                    setSaveBtn(true);
                    setActionBtn(true);
                    setSaveBtnTwo(false);
                    setSaveBtnThree(false);
                    setDcabname(false)
                } else if (data == 'auftrag') {
                    setActionBtn(true);
                    setSaveBtnTwo(true);
                    setSaveBtn(false);
                    setSaveBtnThree(false);
                    setDcabname(false)
                } else if (data == 'order_processing') {
                    setSaveBtn(false);
                    setActionBtn(true);
                    setSaveBtnTwo(false);
                    setSaveBtnThree(false);
                    setDcabname(true)
                } else if (data == 'digitalsign') {
                    setSaveBtn(false);
                    setSaveBtnTwo(false);
                    setSaveBtnThree(true);
                    setActionBtn(true);
                    setDcabname(false)
                }
                else if (data == 'accounting') {
                    setActionBtn(true);
                    setSaveBtn(false);
                    setSaveBtnTwo(false);
                    setDcabname(false)
                } else {
                    setSaveBtn(false);
                    setSaveBtnTwo(false);
                    setActionBtn(true);
                    setDcabname(false)
                }
            } else {
                errorNotify();
                setActionBtn(true)
                setSaveBtn(false);
                setSaveBtnTwo(false);
                activeDataTab('callcenter');
                setDeActiveDist(true);
                callCenter.current.classList.add('active');
                document.querySelector('.moving-tab').style.transform = 'translate3d(-6px, 0px, 0px)';
            }
        }
    }

    const renderDestributaionActionMenu = (
        <Menu
            anchorEl={menu}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
            open={Boolean(menu)}
            onClose={closeMenu}
            keepMounted
        >
            <MenuItem onClick={() => { closeMenu(); document.getElementById('auftrag').click(); }}>{t('Create Order')}</MenuItem>
            <MenuItem onClick={closeMenu}>{t('Download Quotation PDF')}</MenuItem>
        </Menu>
    );

    function editDst() {
        document.getElementById('editdstBtn').click();
    }

    function saveOrder() {
        document.getElementById('saveOrderBtn').click();
    }

    function saveOrderSign() {
        document.getElementById('saveSign').click();
    }

    function DcAcAbnahmeprotokoll() {
        if (document.getElementById('acAbname')) {
            document.getElementById('acAbname').click();
        }

        if (document.getElementById('saveSign')) {
            document.getElementById('saveSign').click();
        }

        if (document.getElementById('dcAbname')) {
            document.getElementById('dcAbname').click();
        }
    }

    const ACApprove = () => {
        if (!params?.id) return;

        callFetch("approve/ac-project", "POST", {
            id: params?.id,
            ac_approve: (approve ? 0 : 1),
        }).then((res) => {
            setRefresh(refresh + 1);
        });
    };

    const FertigmeldungErfolgreich = () => {
        if (!params?.id) return;

        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-primary me-2',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        });

        return swalWithBootstrapButtons.fire({
            text: t("Are you sure?"),
            // icon: 'warning',
            showCancelButton: true,
            confirmButtonText: t('Yes, do it!'),
            cancelButtonText: t('Cancel')
        }).then((result) => {
            if (result.isConfirmed) {
                callFetch("fertigmeldung-erfolgreich", "POST", {
                    id: params?.id,
                    fertigmeldungErfolgreich: (fertigmeldungErfolgreich ? 0 : 1),
                }).then((res) => {
                    setRefresh(refresh + 1);
                    document.getElementById('timeline-refresh-btn').click();
                });
            }
        });
    }

    const storno = (e) => {
        if (!data?.id) return;
        e.preventDefault();

        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-primary me-2',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        });

        return swalWithBootstrapButtons.fire({
            text: t("Are you sure?"),
            // icon: 'warning',
            showCancelButton: true,
            confirmButtonText: t('Yes, do it!'),
            cancelButtonText: t('Cancel')
        }).then((result) => {
            if (result.isConfirmed) {

                var formData = new FormData();
                formData.id = data?.id;
                formData.stornieren = (data?.stornieren == 0 ? 1 : data?.stornieren == 1 ? 2 : 0);

                callFetch("project/stornieren", "POST", formData, {}).then((res) => {
                    // setSaving(false);
                    setRefresh(refresh + 1)
                    document.getElementById("callcenterRefesh3").click();
                    // if (!res.ok) return;
                });

            }
        });
    }


    useEffect(() => {
        if (!params.id) return;

        callFetch("project/related/id/get/" + params.id, "GET", []).then((res) => {
            console.log('NavMenu')
            setData(res?.data);
            if (res?.team_members) {
                setMembers(res?.team_members);
            }
            setApprove(res?.data?.ac_approve);
            setFertigmeldungErfolgreich(res?.data?.fertigmeldungErfolgreich);
        });
    }, [props, params?.id, refresh]);

    return (
        <>
            <div style={{ position: 'relative', transition: 'box-shadow .25s ease-in,background-color .25s ease-in' }}>
                <div class="d-md-flex">
                    <div class="d-flex w-md-50">
                        <SoftBox>
                            {members ? (
                                <SoftBox
                                    component="li"
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <SoftTypography variant="body2" color="text" style={{ fontSize: '0.875rem', marginRight: '15px', color: 'rgb(155, 156, 157)' }}>
                                        Projektteam:
                                    </SoftTypography>
                                    <SoftBox display="flex">{renderMembers}</SoftBox>
                                </SoftBox>
                            ) : null}
                        </SoftBox>
                    </div>

                    <div class="d-flex justify-content-end w-md-50 text-right res-options mt-3 mt-md-0">
                        {activeTab == 'callcenter' && JSON.parse(Cookies.get('permissions')).indexOf("PCCU") !== -1 ? (
                            <button className="btn btn-primary btn-primary-save mb-0" onClick={() => {
                                document.getElementById("callSubmitBtn").click();
                            }}>{t('Save')}</button>
                        ) : <></>}

                        {activeTab == 'roof-planner' && JSON.parse(Cookies.get('permissions')).indexOf("RofC") !== -1 ? (
                            <button className="btn btn-primary btn-primary-save mb-0" onClick={() => {
                                document.getElementById("roofPlanSave").click();
                            }}>{t('Save')}</button>
                        ) : <></>}

                        {saveBtn && JSON.parse(Cookies.get('permissions')).indexOf("PDU") !== -1 ? (
                            <button className="btn btn-primary btn-primary-save mb-0" onClick={() => editDst()}>{t('Save')}</button>
                        ) : <></>}

                        {saveBtnTwo ? (
                            <button className="btn btn-primary btn-primary-save mb-0" onClick={() => saveOrder()}>{t('Save')}</button>
                        ) : <></>}

                        {saveBtnThree ? (
                            <button className="btn btn-primary btn-primary-save mb-0" onClick={() => saveOrderSign()}>{t('Save')}</button>
                        ) : <></>}

                        {(dcabname && JSON.parse(Cookies.get('permissions')).indexOf("dabhU") !== -1) || (dcabname && JSON.parse(Cookies.get('permissions')).indexOf("acAbhU") !== -1) ? (
                            <button className="btn btn-primary btn-primary-save mb-0" onClick={() => DcAcAbnahmeprotokoll()}>{t('Save')}</button>
                        ) : <></>}

                        {actionBtn ? (
                            <>
                                <SoftButton className="ms-3" variant={menu ? "contained" : "outlined"} color="dark" onClick={openMenu}>
                                    {t('Actions')}&nbsp;
                                    <Icon>keyboard_arrow_down</Icon>
                                </SoftButton>
                                <Menu
                                    anchorEl={menu}
                                    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                                    transformOrigin={{ vertical: "top", horizontal: "left" }}
                                    open={Boolean(menu)}
                                    onClose={closeMenu}
                                    keepMounted
                                >
                                    <>
                                        {activeTab == 'distribution' ? (
                                            <>
                                                {JSON.parse(Cookies.get('permissions')).indexOf("OPR") !== -1 ? (
                                                    <>
                                                        <MenuItem onClick={() => { closeMenu(); document.getElementById('auftrag').click(); }}>{t('Create Order')}</MenuItem>
                                                    </>
                                                ) : ''}
                                                {JSON.parse(Cookies.get('permissions')).indexOf("PDU") !== -1 ? (
                                                    <>
                                                        <MenuItem onClick={closeMenu}><a target={"_blank"} rel="noreferrer" href={process.env.REACT_APP_BACKEND_URL + 'quation/' + data?.quations?.id} >{t('Quotation PDF')}</a></MenuItem>
                                                        <MenuItem id="digitalSignQuation" onClick={() => { closeMenu(); activeDataTab('digitalSignQuation') }}>{t('Sign Quotation')}</MenuItem>
                                                    </>
                                                ) : ''}
                                                <MenuItem onClick={closeMenu}><a target={"_blank"} rel="noreferrer" href={process.env.REACT_APP_BACKEND_URL + 'quation-new/' + data?.quations?.id} >{t('Data aquision sheet')}</a></MenuItem>
                                            </>
                                        ) : activeTab == 'auftrag' ? (
                                            <>
                                                <MenuItem onClick={closeMenu}><a target={"_blank"} rel="noreferrer" href={process.env.REACT_APP_BACKEND_URL + 'order/' + data?.quations?.order?.id} >{t('Order PDF')}</a></MenuItem>
                                                {JSON.parse(Cookies.get('permissions')).indexOf("IC") !== -1 ? (
                                                    <MenuItem onClick={() => { closeMenu(); document.getElementById('invoices').click(); }}>{t('Create Invoice')}</MenuItem>
                                                ) : <></>}
                                                {JSON.parse(Cookies.get('permissions')).indexOf("OPR") !== -1 ? (
                                                    <MenuItem id="digitalSign" onClick={() => { closeMenu(); activeDataTab('digitalsign') }}>{t('Sign Order')}</MenuItem>
                                                ) : <></>}
                                            </>
                                        ) : activeTab == 'digitalsign' ? (
                                            <>
                                                <MenuItem onClick={closeMenu}><a target={"_blank"} rel="noreferrer" href={process.env.REACT_APP_BACKEND_URL + 'order/' + data?.quations?.order?.id} >{t('Order PDF')}</a></MenuItem>
                                                {JSON.parse(Cookies.get('permissions')).indexOf("IC") !== -1 ? (
                                                    <MenuItem onClick={() => { closeMenu(); document.getElementById('invoices').click(); }}>{t('Create Invoice')}</MenuItem>
                                                ) : <></>}
                                                {JSON.parse(Cookies.get('permissions')).indexOf("OPR") !== -1 ? (
                                                    <MenuItem id="digitalSign" onClick={() => { closeMenu(); activeDataTab('digitalsign') }}>{t('Sign Order')}</MenuItem>
                                                ) : <></>}
                                            </>
                                        ) : activeTab == 'accounting' ? (
                                            <>
                                                {invoices && invoices.map((inv) => <MenuItem onClick={closeMenu}><a target={"_blank"} rel="noreferrer" href={process.env.REACT_APP_BACKEND_URL + 'invoice/' + inv.id} >{t('Invoice PDF') + ' '}{inv?.id}</a></MenuItem>)}
                                            </>
                                        ) : activeTab == 'order_processing' ? (
                                            <>
                                                {JSON.parse(Cookies.get('permissions')).indexOf("IC") !== -1 ? (
                                                    <>
                                                        <MenuItem onClick={() => { closeMenu(); document.getElementById('invoices').click(); }}>{t('Create Invoice')}</MenuItem>
                                                    </>
                                                ) : <></>}
                                                <MenuItem onClick={() => { closeMenu(); document.getElementById('SignDC').click() }}>{t('Sign DC')}</MenuItem>

                                                {JSON.parse(Cookies.get('permissions')).indexOf("acAbhR") !== -1 ? (
                                                    <MenuItem onClick={() => { closeMenu(); document.getElementById('SignAC').click() }}>{t('Sign AC')}</MenuItem>
                                                ) : ''}

                                                <MenuItem
                                                    onClick={() => {
                                                        ACApprove();
                                                    }}
                                                >{t(approve ? t('AC Approved') : t('AC Approve'))}</MenuItem>

                                                <MenuItem onClick={closeMenu}>{t('Cancel Project')}</MenuItem>
                                            </>
                                        ) : activeTab == 'fertigmeldung_versenden' ? (
                                            <>
                                                <MenuItem onClick={() => { closeMenu(); FertigmeldungErfolgreich() }}>{t('Fertigmeldung erfolgreich')}</MenuItem>
                                            </>
                                        ) : <></>}

                                        {Cookies.get("user") && JSON.parse(Cookies.get("user")).rolename == "Super Admin" ||
                                            Cookies.get("user") && JSON.parse(Cookies.get("user")).rolename == "Admin" ||
                                            Cookies.get("user") && JSON.parse(Cookies.get("user")).rolename == "Sales Person"
                                            ? (
                                                <>
                                                    <MenuItem onClick={closeMenu} data-bs-toggle="modal" data-bs-target="#correctionRequest" >{t('Correction Request')}</MenuItem>

                                                    {data?.id ? (
                                                        <MenuItem
                                                            onClick={(e) => {
                                                                storno(e);
                                                            }}
                                                        >{t(data?.stornieren == 1 ? "Projekt Storniert" : data?.stornieren == 2 ? "Storno aufgehoben" : "Stornieren")}</MenuItem>
                                                    ) : ''}
                                                </>
                                            ) : ''}


                                        {JSON.parse(Cookies.get('permissions')).indexOf("RecC") !== -1 ? (
                                            <MenuItem onClick={closeMenu} data-bs-toggle="modal" data-bs-target="#createReclamationModalOpen">{t('Reklamation anlegen')}</MenuItem>
                                        ) : <></>}

                                        {JSON.parse(Cookies.get('permissions')).indexOf("TicC") !== -1 ? (
                                            <MenuItem onClick={closeMenu} data-bs-toggle="modal" data-bs-target="#createTicketModalOpen">{t('Ticket anlegen')}</MenuItem>
                                        ) : <></>}

                                        {/*JSON.parse(Cookies.get('permissions')).indexOf("TicC") !== -1 ? (
                                            <MenuItem> <a style={{ color: 'inherit' }} rel="noreferrer" target="_blank" href={'/order-overview/' + params.id}>{t('Order Overview')}</a> </MenuItem>
                                        ) : <></>*/}
                                    </>
                                </Menu>
                            </>
                        ) : <></>}
                    </div>
                </div>
            </div>
            <div className="row align-items-center mt-4">
                <div className="col-lg-12 col-sm-12">
                    <div className="nav-wrapper position-relative end-0">
                        <ul className="nav nav-pills nav-fill blur shadow-blur p-3 projecttab" role="tablist">

                            {JSON.parse(Cookies.get('permissions')).indexOf("PCCR") !== -1 ? (
                                <li className="nav-item" id="callcenter" role="presentation">
                                    <a ref={callCenter} id="callcenter_a" onClick={() => { activeDataTab('callcenter') }} href="#" className={"nav-link mb-0 px-0 py-1 " + (props?.currentActiveTab == 'callcenter' ? 'active' : '')} data-bs-toggle="tab" role="tab" aria-selected="true">
                                        {t('Kundendaten')}
                                    </a>
                                </li>
                            ) : <></>}

                            {JSON.parse(Cookies.get('permissions')).indexOf("RofR") !== -1 ? (
                                <li className="nav-item" role="presentation">
                                    <a onClick={() => { activeDataTab('roof-planner') }} href="#" className={"nav-link mb-0 px-0 py-1 " + (props?.currentActiveTab == 'roof-planner' ? 'active' : '')} data-bs-toggle="tab" role="tab" aria-selected="false">
                                        {t('Roof Planner')}
                                    </a>
                                </li>
                            ) : <></>}

                            {JSON.parse(Cookies.get('permissions')).indexOf("PDR") !== -1 ? (
                                <li className="nav-item" id="distribution_li">
                                    {deActiveDist ? <a style={{ boxShadow: 'none' }} onClick={() => { activeDataTab('distribution') }} id="distribution" href="#" className={"nav-link mb-0 px-0 py-1 " + (props?.currentActiveTab == 'distribution' ? 'active' : '')} data-bs-toggle="tab" role="tab" aria-selected="false">
                                        {t('Angebot')}
                                    </a> : ''}
                                    {!deActiveDist ? <a onClick={() => { activeDataTab('distribution') }} id="distribution" href="#" className={"nav-link mb-0 px-0 py-1 " + (props?.currentActiveTab == 'distribution' ? 'active' : '')} data-bs-toggle="tab" role="tab" aria-selected="false">
                                        {t('Angebot')}
                                    </a> : ''}
                                </li>
                            ) : <></>}

                            {JSON.parse(Cookies.get('permissions')).indexOf("OPR") !== -1 ? (
                                <li className="nav-item">
                                    <a onClick={() => { activeDataTab('auftrag') }} href="#" className={"nav-link mb-0 px-0 py-1 " + (props?.currentActiveTab == 'auftrag' ? 'active' : '')} id={"auftrag"} data-bs-toggle="tab" role="tab" aria-selected="false">
                                        {t('Auftrag')}
                                    </a>
                                </li>
                            ) : <></>}

                            {JSON.parse(Cookies.get('permissions')).indexOf("materialliste") !== -1 ? (
                                <li className="nav-item">
                                    <a onClick={() => { activeDataTab('materialliste') }} href="#" className={"nav-link mb-0 px-0 py-1 " + (props?.currentActiveTab == 'materialliste' ? 'active' : '')} id={"materialliste"} data-bs-toggle="tab" role="tab" aria-selected="false">
                                        {t('Beschaffung')}
                                    </a>
                                </li>
                            ) : ''}



                            {JSON.parse(Cookies.get('permissions')).indexOf("dcabhR") !== -1 || JSON.parse(Cookies.get('permissions')).indexOf("acAbhR") !== -1 ? (
                                <li className="nav-item">
                                    <a onClick={() => {

                                        if ((data?.dc_product == 1 && data?.ac_product == 0) || (data?.dc_product == 0 && data?.ac_product == 1) || (data?.dachmontag != null || data?.dachmontag?.id || data?.reclamation_abnahme == true) && params?.id) {
                                            activeDataTab('order_processing');
                                        } else {
                                            toast(t("Please complete leitstand first"));
                                            document.getElementById('callcenter_a').click();
                                            document.getElementById('callcenter').click();
                                        }
                                    }} href="#" id="order_processing" className={"nav-link mb-0 px-0 py-1 " + (props?.currentActiveTab == 'order_processing' ? 'active' : '')} data-bs-toggle="tab" role="tab" aria-selected="false">
                                        {t('AC & DC Montage')}
                                    </a>
                                </li>
                            ) : <></>}

                            {/* {JSON.parse(Cookies.get('permissions')).indexOf("PFR") !== -1 ? (
                                <li className="nav-item">
                                    <a onClick={() => { activeDataTab('files') }} href="#" className={"nav-link mb-0 px-0 py-1 " + (props?.currentActiveTab == 'files' ? 'active' : '')} data-bs-toggle="tab" role="tab" aria-selected="false">
                                        {t('Files')}
                                    </a>
                                </li>
                            ) : <></>}

                            {JSON.parse(Cookies.get('permissions')).indexOf("PNR") !== -1 ? (
                                <li className="nav-item">
                                    <a onClick={() => { activeDataTab('notes') }} href="#" className={"nav-link mb-0 px-0 py-1 " + (props?.currentActiveTab == 'notes' ? 'active' : '')} data-bs-toggle="tab" role="tab" aria-selected="false">
                                        {t('Notes')}
                                    </a>
                                </li>
                            ) : <></>}

                            {JSON.parse(Cookies.get('permissions')).indexOf("RecR") !== -1 || JSON.parse(Cookies.get('permissions')).indexOf("TicR") !== -1 ? (
                                <li className="nav-item">
                                    <a onClick={() => { activeDataTab('service') }} href="#" className={"nav-link mb-0 px-0 py-1 " + (props?.currentActiveTab == 'service' ? 'active' : '')} data-bs-toggle="tab" role="tab" aria-selected="false">
                                        {t('Service')}
                                    </a>
                                </li>
                            ) : <></>} */}

                            {JSON.parse(Cookies.get('permissions')).indexOf("PAR") !== -1 ? (
                                <li className="nav-item">
                                    <a onClick={() => {
                                        if (params?.id) {
                                            if (((data?.quations?.order?.invoices).length > 0) || (data?.dc_product == 0 && data?.ac_product == 1) || (data?.dc_product == 1 && data?.ac_product == 0)) {
                                                activeDataTab('accounting')
                                            } else {
                                                callFetch("project/DCAbhnamheCheck/" + params?.id, "GET", []).then((res) => {
                                                    if (res?.message == 'success') {
                                                        activeDataTab('accounting')
                                                    } else {
                                                        toast(t("Fill Out DC Abnahmeprotokol"));
                                                    }
                                                })
                                            }
                                        } else {
                                            errorNotify();
                                        }
                                    }}
                                        href="#" className="nav-link mb-0 px-0 py-1 " id={"invoices"} data-bs-toggle="tab" role="tab" aria-selected="false">
                                        {t('Accounting')}
                                    </a>
                                </li>
                            ) : <></>}

                            {JSON.parse(Cookies.get('permissions')).indexOf("Fertigmeldung") !== -1 ? (
                                <li className="nav-item">
                                    <a onClick={() => { activeDataTab('fertigmeldung_versenden') }} href="#" className="nav-link mb-0 px-0 py-1 " data-bs-toggle="tab" role="tab" aria-selected="false">
                                        {t('Fertigmeldung')}
                                    </a>
                                </li>
                            ) : <></>}
                        </ul>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    )
}

export default NavMenu;
